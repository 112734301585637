import React, { useEffect } from 'react';
import { spline } from '@georgedoescode/spline';
import SimplexNoise from 'simplex-noise';

import Layout from '../components/layout';
import Seo from '../components/seo';
import { Container, MinPageHeight } from '../styles/globalStyles';
import styled from '@emotion/styled';
import usePrefersReducedMotion from '../hooks/usePrefersReducedMotion';

const Blob = styled.div`
  display: grid;
  place-items: center;
  :root {
    --startColor: hsl(0, 100%, 75%);
    --stopColor: hsl(0, 100%, 75%);
  }

  svg {
    width: min(80vw, 500px);
    height: min(80vw, 500px);
  }

  path {
    cursor: pointer;
  }
`;

const NotFoundText = styled.div`
  text-align: center;
`;

const NotFoundPage = () => {
  const shouldAnimate = !usePrefersReducedMotion();
  useEffect(() => {
    // https://georgefrancis.dev/writing/build-a-smooth-animated-blob-with-svg-and-js/
    function createPoints() {
      const points = [];
      // how many points do we need
      const numPoints = 8;
      // used to equally space each point around the circle
      const angleStep = (Math.PI * 2) / numPoints;
      // the radius of the circle
      const rad = 80;

      for (let i = 1; i <= numPoints; i++) {
        // x & y coordinates of the current point
        const theta = i * angleStep;

        const x = 100 + Math.cos(theta) * rad;
        const y = 100 + Math.sin(theta) * rad;

        // store the point's position
        points.push({
          x: x,
          y: y,
          // we need to keep a reference to the point's original point for when we modulate the values later
          originX: x,
          originY: y,
          // more on this in a moment!
          noiseOffsetX: Math.random() * 1000,
          noiseOffsetY: Math.random() * 1000,
        });
      }

      return points;
    }

    // our <path> element
    const path = document.querySelector('#blob path');
    // used to set our custom property values
    const root = document.documentElement;

    let hueNoiseOffset = 0.1;
    let noiseStep = 0.006;

    const simplex = new SimplexNoise();

    const points = createPoints();

    function map(n, start1, end1, start2, end2) {
      return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
    }

    function noise(x, y) {
      return simplex.noise2D(x, y);
    }

    document.querySelector('path').addEventListener('click', () => {
      noiseStep = 0.019;
      setTimeout(() => {
        noiseStep = 0.006;
      }, 250);
    });

    (function animate() {
      path.setAttribute('d', spline(points, 1, true));

      // for every point...
      for (let i = 0; i < points.length; i++) {
        const point = points[i];

        // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
        const nX = noise(point.noiseOffsetX, point.noiseOffsetX);
        const nY = noise(point.noiseOffsetY, point.noiseOffsetY);
        // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
        const x = map(nX, -1, 1, point.originX - 20, point.originX + 20);
        const y = map(nY, -1, 1, point.originY - 20, point.originY + 20);

        // update the point's current coordinates
        point.x = x;
        point.y = y;

        // progress the point's x, y values through "time"
        point.noiseOffsetX += noiseStep;
        point.noiseOffsetY += noiseStep;
      }

      const hueNoise = noise(hueNoiseOffset, hueNoiseOffset);
      const hue = map(hueNoise, -1, 1, 0, 360);

      root.style.setProperty('--startColor', `hsl(${hue}, 100%, 75%)`);
      root.style.setProperty('--stopColor', `hsl(${hue + 60}, 100%, 75%)`);

      hueNoiseOffset += noiseStep / 6;

      if (shouldAnimate) {
        requestAnimationFrame(animate);
      }
    })();
  }, [shouldAnimate]);

  return (
    <Layout>
      <Seo title="404: Page not found" />
      <MinPageHeight>
        <Container>
          <NotFoundText>
            <h1>Page Not found</h1>
          </NotFoundText>
          <Blob id="blob">
            <svg viewBox="0 0 200 200">
              <defs>
                <linearGradient id="gradient" gradientTransform="rotate(90)">
                  <stop
                    id="gradientStop1"
                    offset="0%"
                    stopColor="var(--startColor)"
                  />
                  <stop
                    id="gradientStop2 "
                    offset="100%"
                    stopColor="var(--stopColor)"
                  />
                </linearGradient>
              </defs>
              <path d="" fill="url('#gradient')"></path>
            </svg>
          </Blob>
          <NotFoundText>
            <p>
              P.S. Click the blob.{' '}
              <a
                href="https://georgefrancis.dev/writing/build-a-smooth-animated-blob-with-svg-and-js/"
                target="_blank"
                rel="noreferrer"
              >
                Credit
              </a>
            </p>
          </NotFoundText>
        </Container>
      </MinPageHeight>
    </Layout>
  );
};

export default NotFoundPage;
